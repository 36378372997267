<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      :checked-rows.sync="checkedRows"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="name"
      :data="offers"
    >
      <b-table-column
        label="Nr.crt."
        field="nrCrt"
        sortable
        v-slot="props"
        centered
      >
        {{ props.row.nrCrt }}
      </b-table-column>
      <b-table-column
        label="Data inregistrarii"
        field="registrationDate"
        sortable
        v-slot="props"
        centered
      >
        {{ props.row.registrationDate | formatDate }}
      </b-table-column>
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            v-if="column.searchable && !column.numeric"
            #searchable="props"
          >
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Cauta..."
              icon="magnify"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row[column.field] }}
          </template>
        </b-table-column>
      </template>
      <b-table-column
        label="Process verbal procedura"
        field="procesVerbalProcedura"
        sortable
        v-slot="props"
        centered
      >
        {{ props.row.procesVerbalProcedura | formatDate }}
      </b-table-column>
      <b-table-column
        label="Process verbal finalizare"
        field="procesVerbalFinalizare"
        sortable
        v-slot="props"
        centered
      >
        {{ props.row.procesVerbalFinalizare | formatDate }}
      </b-table-column>
      <b-table-column
        custom-key="actions"
        cell-class="is-actions-cell"
        v-slot="props"
      >
        <div class="buttons is-right">
          <router-link
            :to="{ name: 'sale.edit', params: { id: props.row.id } }"
            class="button is-small is-primary"
          >
            <b-icon icon="file-edit" size="is-small" />
          </router-link>
          <button
            class="button is-small is-danger"
            type="button"
            @click.prevent="trashModal(props.row)"
          >
            <b-icon icon="trash-can" size="is-small" />
          </button>
        </div>
      </b-table-column>
      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Se incarca datele...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nu exista inregistrari</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import ModalBox from "@/components/ModalBox";

export default {
  name: "SalesTable",
  components: { ModalBox },
  props: {
    offers: {
      type: Array,
      default: null,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalActive: false,
      trashObject: null,
      clients: [],
      isLoading: false,
      paginated: true,
      perPage: 10,
      checkedRows: [],
      columns: [
        {
          field: "id",
          label: "ID",
          numeric: true,
          visible: false,
        },
        {
          field: "sellerFirstNameLastName",
          label: "Vanzator -nume si prenume-",
          numeric: false,
          sortable: true,
          centered: true,
          searchable: true,
          visible: true,
        },
        {
          field: "sellerAddress",
          label: "Vanzator -adresa-",
          numeric: false,
          sortable: true,
          centered: true,
          searchable: true,
        },
        {
          field: "surface",
          label: "Suprafata teren (mp)",
          numeric: true,
          sortable: true,
          centered: true,
        },
        {
          field: "landRegistryNumber",
          label: "Nr. carte funciara",
          numeric: false,
          sortable: true,
          centered: true,
          searchable: true,
        },
        {
          field: "category",
          label: "Categorie folosinta",
          numeric: false,
          sortable: true,
          searchable: true,
          centered: true,
        },
        {
          field: "price",
          label: "Pret de vanzare",
          numeric: true,
          sortable: true,
          centered: true,
        },
        {
          field: "tarla",
          label: "Tarla",
          numeric: false,
          sortable: true,
          searchable: true,
          centered: true,
        },
        {
          field: "parcela",
          label: "Parcela",
          numeric: false,
          sortable: true,
          searchable: true,
          centered: true,
        },
        {
          field: "buyer",
          label: "Cumparator",
          numeric: false,
          sortable: true,
          searchable: true,
        },
      ],
    };
  },
  computed: {
    trashObjectName() {
      if (this.trashObject) {
        return (
          "parcela " +
          this.trashObject.parcela +
          ", tarla " +
          this.trashObject.tarla
        );
      }

      return null;
    },
  },
  mounted() {
    this.isLoading = false;
    if (this.offers && this.offers > 0) {
      if (this.offers.length > this.perPage) {
        this.paginated = true;
      }
    }
  },
  methods: {
    trashModal(trashObject) {
      this.trashObject = trashObject;
      this.isModalActive = true;
    },
    trashConfirm() {
      this.isModalActive = false;
      this.$emit("handleDeleteSale", this.trashObject.id);
      this.$buefy.snackbar.open({
        message: "Inregistrea a fost stearsa.",
        queue: false,
      });
    },
    trashCancel() {
      this.isModalActive = false;
    },
  },
};
</script>

<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      <b-button class="button is-primary" @click="handleAddSale">
        Inregistrare noua
      </b-button>
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Inregistrari"
        icon="file-multiple"
      >
        <sales-table :offers="offers" @handleDeleteSale="handleDeleteSale" />
      </card-component>
      <hr />
    </section>
  </div>
</template>

<script>
import ElectronicRegisterSalesOffer from "@/services/electronicRegisterSalesOffer.service";
const _ = require("lodash");

import SalesTable from "@/components/sales/sales-table";
import CardComponent from "@/components/CardComponent";
import TitleBar from "@/components/TitleBar";
import HeroBar from "@/components/HeroBar";
export default {
  name: "Sales",
  components: {
    HeroBar,
    TitleBar,
    CardComponent,
    SalesTable,
  },
  data() {
    return {
      offers: [],
    };
  },
  created() {
    this.getCityHallSaleOffers().then((response) => {
      let groups = this.groupBy(response, "year");
      for (var i = 0; i < groups.length; i++) {
        var group = groups[i];
        for (var el = 0; el < group.length; el++) {
          group[el].nrCrt = el + 1;
          this.offers.push(group[el]);
        }
      }
      this.offers = _.orderBy(this.offers, ["year", "nrCrt"], ["desc", "desc"]);
    });
  },
  methods: {
    groupBy(arr, prop) {
      const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
      arr.forEach((obj) => map.get(obj[prop]).push(obj));
      return Array.from(map.values());
    },
    async getCityHallSaleOffers() {
      const { handleGetAllSales } = ElectronicRegisterSalesOffer;
      const response = await handleGetAllSales();
      const { data } = response;
      return data;
    },
    async handleAddSale() {
      this.$router.push({
        path: "/sales/add",
      });
    },
    async handleDeleteSale(id) {
      const { handleDeleteSale } = ElectronicRegisterSalesOffer;
      const response = await handleDeleteSale(id);
      const { data, status } = response;
      if (status == "200") {
        const array = this.offers;
        const index = this.offers.findIndex((prop) => prop.id === id);
        this.offers.splice(index, 1);
      } else {
      }
    },
  },
  computed: {
    titleStack() {
      return ["Registru Evidenta Vanzare Terenuri Agricole", "Vanzari"];
    },
    heroRouterLinkTo() {
      return { name: "/sale/add" };
    },
  },
};
</script>
